/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.filter-white{
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)
}

.filter-gray{
    filter: invert(30%) sepia(0%) saturate(1%) hue-rotate(116deg) brightness(93%) contrast(89%)
}


.loading {
    font-size: 5em;
    margin-left: 30px;
    margin-top: -60px;
    margin-bottom: 10px;
    color: gray;
}

.loading::after {
    display: inline-block;
    animation: dotty steps(1,end) 1s infinite;
    content: '';
}

@keyframes dotty {
    0%   { content: ''; }
    25%  { content: '.'; }
    50%  { content: '..'; }
    75%  { content: '...'; }
    100% { content: ''; }
}

.messages {
    font-family: 'OpenSans', sans-serif;
    src: local('OpenSans'), url(./fonts/Roboto/Roboto-Regular.ttf) format('ttf');
}
